<template>
    <v-app>
        <v-card width="500" outlined class="mx-auto my-auto" :loading="loading">
            <v-container class="my-auto">
                <v-row>
                    <v-card-title>
                        <v-icon large left>mdi-sigma</v-icon>
                        <span class="title font-weight-light">{{ title }}</span>
                    </v-card-title>
                </v-row>

                <v-row
                    ><v-col
                        lg="12"
                        md="12"
                        xs="12"
                        cols="12"
                        align="center"
                    ></v-col
                ></v-row>

                <v-row>
                    <v-tabs grow>
                        <v-tab v-on:click="changeLogin(1)">Email</v-tab>
                        <!-- <v-tab v-on:click="changeLogin(2)">Mobile</v-tab> -->
                    </v-tabs>
                </v-row>

                <v-row
                    ><v-col
                        lg="12"
                        md="12"
                        xs="12"
                        cols="12"
                        align="center"
                    ></v-col
                ></v-row>

                <template v-if="showLoginEmail">
                    <v-row>
                        <v-col
                            lg="12"
                            md="12"
                            xs="12"
                            cols="12"
                            class="pl-0 pr-0"
                        >
                            <v-form
                                ref="loginForm"
                                v-model="infoValid"
                                autocomplete="chrome-off"
                            >
                                <v-col lg="12" md="12" xs="12" cols="12">
                                    <v-text-field
                                        label="email"
                                        solo
                                        flat
                                        prepend-inner-icon="mdi-email"
                                        type="email"
                                        v-model="email"
                                        outlined
                                        dense
                                    />
                                    <v-text-field
                                        :type="
                                            !showPassword ? 'password' : 'text'
                                        "
                                        v-model="password"
                                        outlined
                                        dense
                                        label="Password"
                                        solo
                                        flat
                                        prepend-inner-icon="mdi-lock"
                                        :append-icon="
                                            showPassword
                                                ? 'mdi-eye'
                                                : 'mdi-eye-off'
                                        "
                                        @click:append="
                                            showPassword = !showPassword
                                        "
                                    />
                                </v-col>
                            </v-form>
                        </v-col>
                    </v-row>

                    <v-row justify="center"></v-row>

                    <v-row
                        justify="center"
                        v-if="loginStatus == 0 || loginStatus == 1"
                    >
                        <v-col lg="12" md="12" xs="12" cols="12">
                            <v-alert
                                type="success"
                                dense
                                v-if="loginStatus == 1"
                            >
                                {{ loginMsg }}
                            </v-alert>
                            <v-alert
                                type="error"
                                color="red"
                                dense
                                v-if="loginStatus == 0"
                            >
                                {{ loginMsg }}
                            </v-alert>
                        </v-col>
                    </v-row>

                    <v-row justify="center">
                        <v-col lg="12" md="12" xs="12" cols="12" align="center">
                            <v-btn
                                block
                                color="info"
                                :loading="btnLoading"
                                @click="login"
                                :disabled="!infoValid"
                                >Login</v-btn
                            >
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-card-text class="mb-5 pb-0">
                            <p
                                class="text-center font-weight grey--text overline mb-0"
                            >
                                <a @click="redirectForgetPassword"
                                    >Forget Password?</a
                                >
                            </p>
                        </v-card-text>
                    </v-row>
                </template>

                <template v-if="showLoginMobile">
                    <v-row justify="center"
                        ><v-col lg="12" md="12" xs="12" cols="12"></v-col
                    ></v-row>

                    <v-row>
                        <v-col lg="12" md="12" xs="12" cols="12">
                            <div class="d-flex">
                                <v-text-field
                                    :type="'text'"
                                    v-model="mobile"
                                    dense
                                    prefix="61"
                                    outlined
                                    label="Mobile"
                                    flat
                                    solo
                                    prepend-inner-icon="mdi-phone"
                                />
                                <v-btn
                                    dark
                                    color="default"
                                    :disabled="btnSendMessageDisable"
                                    text
                                    elevation="0"
                                    class="height: 40px !important;"
                                    v-on:click="sendCode()"
                                >
                                    {{ btnSendMessage }}
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col lg="12" md="12" xs="12" cols="12">
                            <v-text-field
                                :type="'text'"
                                v-model="code"
                                dense
                                outlined
                                label="Code"
                                solo
                                flat
                                prepend-inner-icon="mdi-lock"
                            />
                            <v-btn
                                block
                                color="info"
                                :disabled="btnCodeValidateDisable"
                                :loading="btnLoadingCodeValidate"
                                v-on:click="messageLogin()"
                                >Login</v-btn
                            >
                        </v-col>
                    </v-row>
                </template>
            </v-container>
        </v-card>
    </v-app>
</template>

<script>
import { snackbar } from "@components/mixins/snackbar";
import { mapState, mapActions } from "vuex";
import crypto from "crypto";

export default {
    mixins: [snackbar],
    data: () => ({
        infoValid: true,
        title: "Sigma Position Keeper",
        email: "",
        password: "",
        redirectUrl: "",
        showPassword: false,
        loading: false,
        showLoginEmail: true,
        showLoginMobile: false,
        btnLoadingCode: false,
        btnLoadingCodeValidate: false,
        btnCodeValidateDisable: true,
        code: "",
        mobile: "",
        btnSendMessage: "Send Code",
        btnSendMessageDisable: false,
        timeCount: 60,
        intervalCount: null,
    }),
    computed: {
        ...mapState(["loginStatus", "loginMsg", "loginBtnLoading"]),
        ...mapState("Auth", ["btnLoading"]),
    },
    watch: {
        btnLoading(nv) {
            if (nv == false) {
                this.loading = false;
            }
        },
        /**
         * toggle button status according to form input
         * @param   {[type]}  nv  [nv description]
         * @param   {[type]}  ov  [ov description]
         * @return  {[type]}      [return description]
         */
        code(nv, ov) {
            if (nv === "") {
                this.btnCodeValidateDisable = true;
            } else {
                this.btnCodeValidateDisable = false;
            }
        },
        /**
         * watch timer change once to 1 reset
         * @param   {[type]}  nv  [nv description]
         * @param   {[type]}  ov  [ov description]
         * @return  {[type]}      [return description]
         */
        timeCount(nv, ov) {
            if (nv === -1) {
                // this.intervalCount = null;
                clearInterval(this.intervalCount);
                this.timeCount = 60;
                this.btnSendMessage = "Send Code";
                this.btnSendMessageDisable = false;
            }
        },
    },
    methods: {
        ...mapActions("Auth", ["loginAction"]),
        /**
         * Counter management for send code button
         * code is allowed to be resent every 1 minutes
         * @return  {[type]}  [return description]
         */
        startCountDown() {
            this.btnSendMessageDisable = true;
            this.intervalCount = setInterval(() => {
                this.btnSendMessage = this.timeCount.toString() + "s resend";
                this.timeCount -= 1;
            }, 1000);
        },
        /**
         * Action to call api to send auth code to mobile
         * @return  {[type]}  [return description]
         */
        sendCode() {
            const self = this;

            if (
                self.mobile === "" ||
                self.mobile === undefined ||
                self.mobile === null
            ) {
                self.snackBarDanger("Please fill in your mobile");
                return;
            }
            const mobile = "61" + self.mobile;
            self.startCountDown();

            const param = { mobile: mobile };
        },
        /**
         * Switch login method
         * @param   {[type]}  type  [type description]
         * @return  {[type]}        [return description]
         */
        changeLogin(type) {
            this.showLoginEmail = false;
            this.showLoginMobile = false;
            if (type === 1) this.showLoginEmail = true;
            if (type === 2) this.showLoginMobile = true;
        },
        /**
         * [redirectForgetPassword description]
         * @return {[type]} [description]
         */
        redirectForgetPassword() {
            this.$router.push({ name: "ForgetPassword" });
        },
        /**
         * Handle user login
         * @return  {[type]}  [return description]
         */
        login() {
            let broker = null;
            let encryptedBrokerInfo = "";
            if (window.location.hostname.includes("pk-")) {
                broker = window.location.hostname
                    .replace("pk-", "")
                    .replace(".sigmarisk.com.au", "")
                    .toLocaleUpperCase();
            } else {
                broker = process.env.VUE_APP_SOCKET_API.replace(
                    "https://socket-",
                    ""
                )
                    .replace(".sigmarisk.com.au", "")
                    .toLocaleUpperCase();
            }
            if(broker ==="BB"){
                broker = "BLUEBERRY"
            }
            // encrypt broker info with secret_key
            const secretKey = "super-secret";
            const hash = crypto.createHash("sha256");
            hash.update(broker + "-" + secretKey);
            encryptedBrokerInfo = hash.digest("hex");

            const self = this;
            const params = { email: self.email, password: self.password, broker: encryptedBrokerInfo};
            this.$store.state.jwtToken = "";
            this.loading = true;
            localStorage.setItem("homeId", "23");
            localStorage.setItem("token", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6Z...");  // Truncated for brevity
            localStorage.setItem("refresh_token", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6Z...");  // Truncated for brevity
            localStorage.setItem("permission", '{"broker":[],"frontPermission":{"book":{"child":[],"function":["homeLoginDetailSummary","extraSymbolDetails","dailySummaryIcon","mtdVolumeColumn","mtdPnlColumn","loginSummaryInfo","loginSummaryStatistic","loginSummaryTrades"]},"lp":{"child":[]},"alert":{"child":["overview"]},"settings":{"child":["alertConfig","brokerConfig","serverConfig","bookConfig","pkTaker","routingConfig","copyTradeConfig","lpConfig","notificationConfig"],"function":["alertConfigEdit"]},"system":{"child":["group","bookGroupRules","userPerformance","snapshot","symbolRule","tick","ticklive","alertConfiguration","notification"]},"account":{"child":[]},"risk":{"child":["prometheus","notification"],"function":[]}},"backendPermission":"*","allowJsonEditor":1,"whitelist":["*"],"blocklist":[]}');
            localStorage.setItem("displayName", "demo.demo");
            localStorage.setItem("alertId", "22");
            localStorage.setItem("currentPath", "/");
            localStorage.setItem("homeProfiles", '[{"id":23,"text":"test","books":[]}]');

            setTimeout(() => {
                self.$router.push({ name: "Home" });
            }, 1000);
            // this.loginAction(params);
        },
        enterPressed(e) {
            if (e.code == "Enter") {
                this.login();
            }
        },
    },
    created() {
        document.addEventListener("keypress", this.enterPressed);
    },
    mounted() {},
    destroyed() {
        document.removeEventListener("keypress", this.enterPressed);
    },
};
</script>
